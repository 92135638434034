export function setElementAttrs(el, attrs, options = {}) {
	Object.entries(attrs).forEach(([attrKey, attrValue]) => {
		if (attrKey === 'style') {
			Object.entries(attrValue).forEach(([styleKey, styleValue]) => {
				el.style[styleKey] = styleValue;
			});
			return;
		}
		if (attrKey === 'class' && options.keepClasses) {
			el.classList.add(...attrValue.split(' '));
		} else {
			el.setAttribute(attrKey, attrValue);
		}
	});
}

export function applyDOMTreeAttrs(rootEl, changes, options = {}) {
	Object.entries(changes).forEach(([selector, attrs]) => {
		const applicableElements = rootEl.querySelectorAll(selector);
		applicableElements.forEach((element) => setElementAttrs(element, attrs, options));
	});
}

// Used for styling and modifying HTML markup in translation strings
// Appllies attributes into an HTML String and return it as a string
// Element targeting is achieved with css selectors as keys
//
// Available options:
//
//		keepClasses: true
//		Will keep existing classes on the element when applying new ones
//
// Example:
//
//		const text = '<div><p>Hello World</p></div>';
//		const attributes = {
//			'div > p': {											Selector like in CSS
//				style: {											Attributes can also have primitives as values
//					color: '#0000FF',
//				},
//			},
//		};
//		applyAttrsInHtmlString(text, attributes);
//
// Returns a string:
//
//		"<div><p style="color: #0000FF">Hello World</p></div>"
//
export function applyAttrsToHtmlString(htmlString, attrs, options = {}) {
	const parser = new DOMParser();
	const parsedContent = parser.parseFromString(htmlString, 'text/html');	// parse string

	applyDOMTreeAttrs(parsedContent, attrs, options);								// apply attributes
	return parsedContent.querySelectorAll('body')[0].innerHTML;				// convert to string and return
}

export default {};
