export default {
	props: {
		highlightColor: {
			type: String,
			default: '',
		},
	},
	methods: {
		makeHighlightStyle(propertyName) {
			if (this.highlightColor) {
				return `${propertyName}: ${this.highlightColor} !important;`;
			}
			return '';
		},
	},
};
