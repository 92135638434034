const colors = {
	primary: '#E73540',
	secondary: '#66B2E8',
	text: '#535353',
};

export default {
	colors,
	hero: {
		background: 'hero/background.png',
		content: [
			{
				text: 'healthy_weight_loss_title',
				highlight: false,
				class: {
					default: {
						big: 'title dark-gray',
						small: 'title text-white',
					},
				},
				style: {
					big: 'line-height: 0.8;',
					small: 'line-height: 0.8;',
				},
			},
			{
				text: 'healthy_weight_loss_subtitle',
				highlight: true,
				class: {
					default: {
						big: 'subtitle lg-letter-spacing',
						small: 'subtitle',
					},
					es: {
						big: 'subtitle lg-letter-spacing smaller-subtitle',
						small: 'subtitle smaller-subtitle',
					},
				},
				style: {
					big: 'font-size: 15vw;',
					small: 'font-size: 25vw;',
				},
			},
		],
		ctas: [
			{
				class: 'text-white',
				scrollTo: 'overview-section',
				text: 'learn_more',
				color: colors.secondary,
				type: 'filled',
			},
		],
	},
	introHeader: {
		title: 'healthy_weight_loss_intro_header_title',
		subtitle: 'healthy_weight_loss_intro_header_subtitle',
	},
	media: {
		sections: [
			{
				reversed: true,
				title: 'healthy_weight_loss_intro_section_1_title',
				content: 'healthy_weight_loss_intro_section_1_content',
				color: colors.secondary,
				media: {
					mediaId: 'zlem-video',
					image: 'overview/zlem-box.png',
					video: {
						en: 'https://player.vimeo.com/video/493519065?autoplay=1',
						es: 'https://player.vimeo.com/video/495260027?autoplay=1',
						ja: 'https://player.vimeo.com/video/493519065?autoplay=1',
					},
					clickable: false,
				},
				cta: {
					showModal: 'zlem-video',
					text: 'watch_video',
					class: 'py-1',
					style: '',
				},
			},
			{
				reversed: false,
				title: 'healthy_weight_loss_intro_section_2_title',
				content: 'healthy_weight_loss_intro_section_2_content',
				color: colors.primary,
				media: {
					mediaId: 'plos-video',
					image: 'overview/plos-box-mocha.png',
					video: {
						en: 'https://player.vimeo.com/video/660749047?autoplay=1',
						es: 'https://player.vimeo.com/video/678421014?autoplay=1',
						ja: 'https://player.vimeo.com/video/660749047?autoplay=1',
					},
					clickable: false,
				},
				cta: {
					showModal: 'plos-video',
					text: 'watch_video',
					class: 'py-1',
					style: '',
				},
			},
		],
	},
	icons: {
		background: 'overview/icons/background.png',
		iconCols: 3,
		includeAddendum: true,
		title: 'healthy_weight_loss_icons_title',
		disclaimer: 'healthy_weight_loss_icons_disclaimer',
		icons: [
			{
				text: 'healthy_weight_loss_overview_icon_1',
				file: 'overview/icons/body-icon.png',
			},
			{
				text: 'healthy_weight_loss_overview_icon_2',
				file: 'overview/icons/metabolism-icon.png',
			},
			{
				text: 'healthy_weight_loss_overview_icon_3',
				file: 'overview/icons/appetite-icon.png',
			},
			{
				text: 'healthy_weight_loss_overview_icon_4',
				file: 'overview/icons/energy-icon.png',
			},
			{
				text: 'healthy_weight_loss_overview_icon_5',
				file: 'overview/icons/lipid-icon.png',
			},
			{
				text: 'healthy_weight_loss_overview_icon_6',
				file: 'overview/icons/drops-icon.png',
			},
		],
	},
	videoHeader: {
		title: 'healthy_weight_loss_video_section_title',
	},
	video: {
		reversed: false,
		content: 'healthy_weight_loss_video_section_content',
		media: {
			mediaId: 'media-video',
			image: 'video/preview.png',
			video: {
				en: 'https://player.vimeo.com/video/701760272?autoplay=1',
				es: 'https://player.vimeo.com/video/701760272?autoplay=1',
				ja: 'https://player.vimeo.com/video/701760272?autoplay=1',
			},
			clickable: true,
		},
	},
	buy: {
		background: 'buynow/buy-now.png',
		subtitle: 'healthy_weight_loss_buy_now_subtitle',
		products: [
			{
				title: 'zlem_title',
				searchName: 'Zlem',
				color: colors.secondary,
			},
			{
				title: 'plos_title_2',
				searchName: 'Plos',
				color: colors.primary,
			},
		],
	},
	contact: {
		background: 'contact/contact-me-background.jpeg',
		title: {
			text: 'contact_title',
			color: colors.text,
			class: 'font-weight-bold',
			style: '',
		},
		content: {
			text: 'contact_content_2',
			color: colors.text,
			class: 'h3',
			style: '',
		},
		image: 'contact/contact-me.png',
		cta: {
			text: 'contact_title',
		},
	},
};
