<template>
	<page-section :background-style="makeHighlightStyle('background-color')">
		<div :class="{ 'mx-5': windowWidth === 'xl' }">
			<div class="row mb-4">
				<div class="col text-center">
					<h2 class="section-header text-white m-0">
						{{ translate('opportunity_quote_title') }}
					</h2>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col text-center">
					<p class="h4 text-white">
						"{{ translate('opportunity_quote_content') }}"
					</p>
				</div>
			</div>
			<div class="row mb-5">
				<div class="col text-center">
					<p class="h6 text-white">
						{{ translate('opportunity_quote_author') }}
					</p>
				</div>
			</div>
			<div class="row justify-content-center">
				<div class="col-auto">
					<div
						v-b-tooltip="{
							trigger: 'hover',
							html: true,
							title: !canRegister ? translate('cant_sponsor_orders_link', { link: openLiveChat }) : '',
						}">
						<button
							class="btn rounded-0 border-2 px-5"
							:class="canRegister ? 'btn-outline-light' : 'btn-outline-secondary'"
							:disabled="!canRegister"
							:style="{ cursor: canRegister ? 'pointer' : 'default !important' }"
							@click="canRegister ? $router.push({ name: 'Register' }) : ''">
							<div
								:class="language !== 'es' ? 'text-capitalize' : ''"
								class="learn-more font-weight-bolder letter-spacing m-0 mx-3"
								:style="['xs', 'sm', 'md'].includes(windowWidth) ? '' : 'font-size: 1.4vw'">
								<template v-if="!loading">
									{{ translate('opportunity_quote_cta') }}
								</template>
								<template v-else>
									<i class="fa fa-fw fa-spinner fa-pulse" /> {{ translate('loading') }}
								</template>
							</div>
						</button>
					</div>
				</div>
			</div>
		</div>
	</page-section>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import { URIS } from '@/settings/LiveChat';
import { LandingPages, Store } from '@/translations';
import PageSection from './PageSection';
import Color from '../mixins/Color';

export default {
	name: 'QuoteSection',
	messages: [LandingPages, Store],
	components: {
		PageSection,
	},
	mixins: [Color, WindowSizes],
	props: {
		highlightColor: {
			type: String,
			required: true,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		canRegister: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			openLiveChat: URIS.MAXIMIZE_CHAT,
		};
	},
};
</script>
