<template>
	<div class="w-100">
		<div
			:class="windowWidth === 'xs' ? 'mt-5 py-5' : ''"
			class="h-100"
			:style="backgroundStyle">
			<div
				class="row"
				:style="backgroundHeight">
				<div class="col d-flex align-items-center justify-content-center">
					<div class="d-block">
						<div class="row justify-content-center">
							<div class="col-auto">
								<img
									:class="logoClass ? logoClass : 'pb-2 mb-5'"
									:style="logoStyle ? logoStyle : 'width: 33vw; min-width: 18rem;'"
									:src="logo">
							</div>
						</div>
						<div
							v-if="!!slogan"
							class="row justify-content-center mb-5">
							<div class="col-auto text-center">
								<span
									class="text-light text-uppercase letter-spacing m-0"
									:style="['xs', 'sm', 'md'].includes(windowWidth) ? 'font-size: 1.5rem;' : 'font-size: 2vw'">
									{{ slogan }}
								</span>
							</div>
						</div>
						<div
							v-else
							class="row mb-3" />
						<div class="row justify-content-center">
							<div class="col-auto">
								<button
									class="btn btn-lg btn-outline-light rounded-0 border-2 px-5"
									@click="scrollTo(scrollTarget)">
									<div
										:class="language !== 'es' ? 'text-capitalize' : ''"
										class="learn-more font-weight-bolder letter-spacing m-0 mx-3"
										:style="['xs', 'sm', 'md'].includes(windowWidth) ? '' : 'font-size: 1.4vw'">
										{{ translate('learn_more') }}
									</div>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import Scroll from '../mixins/Scroll';

export default {
	name: 'LandingPageHero',
	mixins: [Scroll, WindowSizes],
	props: {
		background: {
			type: String,
			required: true,
		},
		logo: {
			type: String,
			required: true,
		},
		slogan: {
			type: String,
			default: '',
		},
		scrollTarget: {
			type: String,
			required: true,
		},
		logoClass: {
			type: String,
			default: '',
		},
		logoStyle: {
			type: String,
			default: '',
		},
	},
	computed: {
		backgroundStyle() {
			const styles = [
				`background-image: url('${this.background}');`,
				'background-size: cover;',
				'background-position: 45% 50%;',
				'background-repeat: none;',
				this.backgroundHeight,
			];

			if (this.$replicated.replicatedSponsor()) {
				styles.push(this.windowWidth === 'xs' ? 'margin-top: 120px !important;' : 'margin-top: 35px !important;');
			}

			return styles.join(' ');
		},
		backgroundHeight() {
			if (this.$replicated.replicatedSponsor()) {
				return this.windowWidth === 'xs' ? 'min-height: 70vh;' : 'min-height: 95vh;';
			}
			return this.windowWidth === 'xs' ? 'min-height: 95vh;' : 'min-height: 100vh;';
		},
	},
};
</script>
<style scoped>
.letter-spacing {
	letter-spacing: 0.1vw;
}
.border-2 {
    border-width: 2px !important;
}
</style>
