import healthyWeightLoss from './HealthyWeightLoss';
import tuun from '@/settings/LandingPages2/Tuun';
import { ONLY_TUUN_COUNTRIES } from '@/settings/Register';

export const DOUBLE_PRODUCT_PAGES = {
	'healthy-weight-loss': healthyWeightLoss,
};

export const PRODUCT_PAGES = {
	tuun,
};

const tuunCountriesRestrictions = () => ONLY_TUUN_COUNTRIES.reduce((acc, country) => ({
	...acc,
	[country]: [
		'bran',
		'zlem',
		'uuth',
		'plos',
		'byom',
		'lite',
		'opportunity-1',
		'healthy-weight-loss',
	],
}), {});

export const RESTRICTED_BY_COUNTRY = {
	default: [],
	NZ: [
		'zlem',
		'healthy-weight-loss',
		'opportunity-1',
	],
	CN: [
		'lite',
	],
	...tuunCountriesRestrictions(),
};

export default {};
