<template>
	<page-section>
		<div class="row">
			<div class="col d-flex flex-column align-items-center">
				<div class="row mb-4 pb-3">
					<div class="col">
						<h2
							class="section-header text-center"
							:style="`color: ${highlightColor};`">
							{{ title }}
						</h2>
					</div>
				</div>
				<div
					:class="{
						'mb-2': ['xs', 'sm', 'md'].includes(windowWidth),
						'mb-5': !['xs', 'sm', 'md'].includes(windowWidth),
					}"
					class="w-100 row no-gutters justify-content-center">
					<div
						:class="['xs', 'sm', 'md'].includes(windowWidth) ? '' : 'px-5'"
						class="col-auto">
						<div class="position-relative">
							<div class="black-overlay w-100 h-100 position-absolute">
								<div
									v-b-modal="'modal-video'"
									class="h-100 w-100 pointer">
									<div class="h-100 w-100 row no-gutters position-static justify-content-center">
										<div class="col-auto d-flex align-items-center">
											<i class="fa fa-5x text-white fa-play" />
										</div>
									</div>
								</div>
							</div>
							<img
								:src="previewImg"
								class="img-fluid w-100">
						</div>
					</div>
				</div>
			</div>
		</div>
		<b-modal
			id="modal-video"
			modal-class="media-modal"
			hide-footer
			centered
			size="lg">
			<div>
				<b-embed
					type="iframe"
					aspect="16by9"
					:src="url[language]"
					allow="autoplay; fullscreen"
					allowfullscreen />
			</div>
		</b-modal>
	</page-section>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import PageSection from './PageSection';

export default {
	name: 'VideoSection',
	components: {
		PageSection,
	},
	mixins: [WindowSizes],
	props: {
		highlightColor: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		url: {
			type: Object,
			default() {
				return {};
			},
		},
		previewImg: {
			type: String,
			required: true,
		},
	},
	computed: {
	},
};
</script>
<style scoped>
.black-overlay{
	background: rgba(23, 23, 25, 0.5);
}
</style>
