<template>
	<div class="bg-white">
		<image-section
			v-for="(section, index) in sections"
			:key="`about-${index}`"
			:highlight-color="highlightColor"
			:image="section.image"
			:title="section.title"
			:content="section.content"
			:reversed="section.reversed"
			:cta="section.cta"
			:current-video-url.sync="currentVideoUrl"
			:row-style="['xs', 'sm'].includes(windowWidth) ? '' : `height: 100% !important;`"
			container-sized
			reduced />
		<b-modal
			id="about-video"
			modal-class="media-modal"
			hide-footer
			centered
			size="lg">
			<div>
				<b-embed
					type="iframe"
					aspect="16by9"
					:src="currentVideoUrl"
					allow="autoplay; fullscreen"
					allowfullscreen />
			</div>
		</b-modal>
	</div>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import ImageSection from './ImageSection';

export default {
	name: 'LandingPageAbout',
	components: {
		ImageSection,
	},
	mixins: [WindowSizes],
	props: {
		highlightColor: {
			type: String,
			required: true,
		},
		sections: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			currentVideoUrl: '',
		};
	},
};
</script>
<style scoped>
</style>
