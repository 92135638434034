import { PRODUCTS_LINKS } from '@/settings/Products';

const colors = {
	primary: '#88939E',
	text: '#535353',
};

export default {
	colors,
	hero: {
		background: 'hero/background.png',
		logo: 'hero/logo.png',
		image: 'hero/tuun-logo.png',
		content: [
			{
				text: 'tuun_hero_description',
				highlight: false,
				class: {
					default: {
						big: 'my-4 pr-5',
						small: 'my-4 text-center',
					},
				},
				style: {
					big: 'line-height: 1.8;',
					small: 'line-height: 1.8;',
				},
			},
		],
		ctas: [
			{
				class: 'text-white',
				scrollTo: 'overview-section',
				text: 'learn_more',
				color: colors.primary,
				type: 'filled',
			},
			{
				scrollTo: 'buy-section',
				text: 'buy_now',
				color: colors.primary,
				type: 'outline',
			},
		],
	},
	overview: {
		reversed: true,
		title: 'tuun_overview_title',
		content: 'tuun_overview_content',
		color: colors.primary,
		media: {
			mediaId: 'overview-image',
			image: 'overview/tuun-pendants.png',
		},
		cta: {
			scrollTo: 'buy-section',
			text: 'buy_now',
			class: 'py-2',
			style: 'min-width: 240px;',
		},
	},
	media: {
		sections: [
			{
				reversed: true,
				title: 'tuun_section_1_title',
				content: 'tuun_section_1_content',
				color: colors.primary,
				media: {
					mediaId: 'zlem-video',
					image: 'overview/girl-with-tuun.png',
					video: {
						en: 'https://player.vimeo.com/video/493519065?autoplay=1',
						es: 'https://player.vimeo.com/video/495260027?autoplay=1',
						ja: 'https://player.vimeo.com/video/493519065?autoplay=1',
					},
					clickable: false,
				},
			},
		],
	},
	icons: {
		background: 'overview/icons/background.jpg',
		iconCols: 3,
		includeAddendum: true,
		icons: [
			{
				text: 'tuun_overview_icon_1',
				file: 'overview/icons/benefits-1.png',
			},
			{
				text: 'tuun_overview_icon_2',
				file: 'overview/icons/benefits-2.png',
			},
			{
				text: 'tuun_overview_icon_3',
				file: 'overview/icons/benefits-3.png',
			},
			{
				text: 'tuun_overview_icon_4',
				file: 'overview/icons/benefits-4.png',
			},
			{
				text: 'tuun_overview_icon_5',
				file: 'overview/icons/benefits-5.png',
			},
			{
				text: 'tuun_overview_icon_6',
				file: 'overview/icons/benefits-6.png',
			},
		],
	},
	videoHeader: {
		title: 'healthy_weight_loss_video_section_title',
	},
	video: {
		reversed: false,
		title: 'tuun_video_section_title',
		content: 'tuun_video_section_content',
		color: colors.primary,
		media: {
			mediaId: 'media-video',
			image: 'video/preview.png',
			video: {
				en: 'https://player.vimeo.com/video/751461036?autoplay=1',
				es: 'https://player.vimeo.com/video/753307305?autoplay=1',
				ja: 'https://player.vimeo.com/video/817685935?autoplay=1',
			},
			clickable: true,
		},
		cta: {
			url: PRODUCTS_LINKS.TN,
			target: '_blank',
			text: 'download_brochure',
			class: 'py-2',
			style: 'min-width: 240px;',
		},
	},
	reviews: {
		videoUrl: '',
		textColor: '#7C7C7C',
		reviews: [
			{
				image: 'reviews/mary-amoedo.png',
				author: 'Mary A, USA',
				content: 'tuun_review_2',
				stars: 5,
			},
			{
				image: 'reviews/natasha-r.png',
				author: 'Natasha R., USA',
				content: 'tuun_review_3',
				stars: 5,
			},
			{
				image: 'reviews/tymiesha-m.png',
				author: 'Tymiesha M., USA',
				content: 'tuun_review_4',
				stars: 5,
			},
			{
				image: 'reviews/rory-r.png',
				author: 'Rory R, USA',
				content: 'tuun_review_5',
				stars: 5,
			},
			{
				image: 'reviews/kaneko-k.png',
				author: 'Kaneko K., Japan',
				content: 'tuun_review_1',
				stars: 5,
			},
		],
		disclaimer: 'tuun_review_disclaimer',
	},
	buy: {
		background: colors.primary,
		title: 'tuun_title',
		searchName: 'Tuun',
	},
	contact: {
		background: 'contact/contact-me-background.jpg',
		title: {
			text: 'contact_title',
			color: colors.primary,
			class: 'font-weight-bolder',
			style: '',
		},
		content: {
			text: 'contact_content_2',
			color: colors.text,
			class: 'h4 font-weight-bold',
			style: 'letter-spacing: 2px;',
		},
		image: 'contact/contact-me.png',
		cta: {
			text: 'contact_title',
		},
	},
};
