// TODO: refactor to not use javascript URIs, and instead use vanilla event listeners within the tooltips

/* eslint-disable no-script-url */
const MAXIMIZE_CHAT = 'javascript:try{document.getElementById(\'liveChatButton\').click();window.LC_API.open_chat_window();void(0);}catch(error){}';
/* eslint-enable no-script-url */

export const URIS = {
	MAXIMIZE_CHAT,
};

export default {};
